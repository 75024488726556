<template lang="pug">
    div
        base-category.mb-2(:categoryData="item" :hasDescendants="hasDescendants")
        template(v-if="hasSubCategories")
            div
                CategoryNode.ml-5(
                    v-for="(sub_category, index) in subCategories"
                    :key="sub_category._id"
                    :item="sub_category"
                )
</template>

<script>
//Components
import BaseCategory from './base-category.vue';
//Store
import { CategoriesNameSpace, CategoriesGetterTypes, CategoriesActionTypes } from '@/store/categories/types';

export default {
    name: "CategoryNode",
    components: {
        'base-category': BaseCategory,
    },
    props: {
        item: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            hasDescendants: false,
        }
    },
    computed: {
        subCategories() {
            const result = this.$store.getters[`${CategoriesNameSpace}/${CategoriesGetterTypes.GetCategories}`].filter(category => {
                return category.parent === this.item._id
            })

            return result
        },
        hasSubCategories() {
            return this.subCategories
        },
    },
    async mounted() {
        await this.onGetCategories({ params: { "ancestors._id": this.item._id }, readonly: true })
    },
    methods: {
        async onGetCategories({ params = null, readonly = false }) { //Для отображения стрелочки - показать подкатегории
            const { data } = await this.$store.dispatch(`${CategoriesNameSpace}/${CategoriesActionTypes.GetCategories}`, { params, readonly })
            if (data.data.length) this.hasDescendants = true
        },
    }
}
</script>