<template lang="pug">
    div
        filters
            slot(slot="filters")
                span Name Search - 
                span Date Range Search
            slot(slot="buttons")
                regular-button.w-250(:value="BUTTONS_VALUES.add_root_category" @click="onAddRootCategory")
        categories
</template>

<script>
//Components
import Categories from '@/components/views/categories';
import Filters from '@/components/common/filters';
import RegularButton from '@/components/common/buttons/regular-button';
//Store
import { CategoriesNameSpace, CategoriesActionTypes, CategoriesMutationTypes } from '@/store/categories/types';
//Config
import { BUTTONS_VALUES } from '@/configs/names.js';
//Helpers
import { getUserAuthData } from '@/helpers/authorization';

export default {
    name: "CategoriesListPage",
    components: {
        'filters': Filters,
        'categories': Categories,
        'regular-button': RegularButton,
    },
    computed: {
        BUTTONS_VALUES() {
            return BUTTONS_VALUES
        },
    },
    mounted() {
        this.onGetCategories({ params: { parent: '%A0' } })
    },
    methods: {
        getUserAuthData() {
            return getUserAuthData()
        },
        async onAddRootCategory() {
            const {data} = await this.$store.dispatch(`${CategoriesNameSpace}/${CategoriesActionTypes.AddCategory}`, {
                params: { 
                    name: 'root category', 
                    slug: 'root_category',
                    order: 1,
                    author: this.getUserAuthData().id,
                }
            })

            this.onGetCategories({ params: { _id: data.id } })
        },
        async onGetCategories({ params }) {
            await this.$store.dispatch(`${CategoriesNameSpace}/${CategoriesActionTypes.GetCategories}`, {params})
        }
    },
    beforeDestroy() {
        this.$store.commit(`${CategoriesNameSpace}/${CategoriesMutationTypes.SetEmptyCategories}`)
    }
}
</script>