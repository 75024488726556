<template lang="pug">
    div
        template(v-for="(item, index) in rootCategories")
            category-node(
                :key="item._id" 
                :item="item" 
                :class="{'mb-2': index !== rootCategories.length - 1}"
            )   
</template>

<script>
//Components
import CategoryNode from './components/category-node';
//Store
import { CategoriesNameSpace, CategoriesGetterTypes } from '@/store/categories/types';

export default {
    name: "Categories",
    components: {
        'category-node': CategoryNode,
    },
    computed: {
        rootCategories() {
            const result = this.$store.getters[`${CategoriesNameSpace}/${CategoriesGetterTypes.GetCategories}`].filter(item => {
                return item.parent === null
            })

            return result
        }
    }
}
</script>s