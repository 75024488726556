import { notifyError, notifySuccess } from '@/helpers/notifications';
import { NOTIFICATIONS } from '@/configs/notifications';

const copyToClipboard = ({ value }) => {
    const cb = navigator.clipboard;
    cb.writeText(value)
        .then(() => {
            notifySuccess({ title: NOTIFICATIONS.data_copied_to_clipboard })
        })
        .catch(errors => {
            notifyError({ title: NOTIFICATIONS.copy_to_clipboard_error, errors: errors })
        });
}

export {copyToClipboard}